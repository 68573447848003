/* Tailwind Imports */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* CSS Reset */

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

img {
	max-width: 100%;
	display: block;
}

body {
	background-color: var(--jetblack);
	scroll-behavior: smooth;
}

/* @media (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
} */

/* Typography */

@font-face {
	font-family: "Akira-Expanded";
	font-display: swap;
	src: local(""), url("./assets/fonts/AkiraExpanded.woff"),
	url("./assets/fonts/AkiraExpanded.woff2");
}

@font-face {
	font-family: "Akira-Bold";
	font-display: swap;
	src: local(""), url("./assets/fonts/AkiraExpanded-Bold.woff"),
	url("./assets/fonts/AkiraExpanded-Bold.woff2");
}

@font-face {
	font-family: "Akira-Outline";
	font-display: swap;
	src: local(""), url("./assets/fonts/AkiraExpanded-Outline.woff"),
	url("./assets/fonts/AkiraExpanded-Outline.woff2");
}

@font-face {
	font-family: "aaux-next";
	font-display: swap;
	src: url("./assets/fonts/aauxnextmdwebfont.otf");
}
@font-face {
	font-family: "aaux-bold";
	font-display: swap;
	src: url("./assets/fonts/AauxNextBlk.otf");
}

/* CSS Variables */

:root {
	--black: #000;
	--white: #fff;
	--gray: #f2f2f2;
	--light-gold: #d1c179;
	--light-gold-fade: #cfc8a9;
	--dark-gold: #bab077;
	--platinum: #e5e5e5;
	--gunmetal: #808080;
	--jetblack: #1a1a1a;
	--success: #6ebe61;
	--danger: #cc0000;
	--caution: #d1c155;

	--main-font: "Akira-Expanded", sans-serif;
	--main-bold: "Akira-Bold", sans-serif;
	--main-outline: "Akira-Outline", sans-serif;

	--secondary-font: "aaux-next", sans-serif;
	--secondary-bold: "aaux-bold", sans-serif;

	--opening-logo-height: 300px;
	--content-bg-haiku-height: 570px;
	--content-bg-torii-height: 650px;
	--opening-typeface-margin: calc(var(--opening-logo-height) * 0.5);

	--vh: 1vh;
}

/* BASE STYLES*/

.alert-container {
	background-color: #f2f2f210;
	border-radius: 15px;
	width: 500px;
	height: 150px;
}
.alert-message {
	text-align: center;
	margin: 0 auto;
	margin-top: 10%;
	color: white;
}

.ascension_id {
	font-family: var(--secondary-font);
}

.ascend_img {
	width: calc(100vw * 0.2084);
	height: calc(100vw * 0.2084);
}

.ascend_img_pending {
	width: calc(100vw * 0.2084);
	height: calc(100vw * 0.2084);
	background-color: black;
	border: var(--light-gold) 1px solid;
}

.ascend_img_container {
	position: absolute;
	top: calc(100vw * 0.07);
	margin-left: calc(100vw * 0.0592);
}

.ascension_block {
	position: relative;
	max-width: 100%;
	max-height: 100%;
	width: calc(100vw * 0.357);
	height: calc(100vw * 0.417);
	z-index: -0.1;
}

.ascend_ticket {
	position: relative;
	width: calc(100vw * 0.2434);
	margin: 0 auto;
}

.app {
	height: auto;
	z-index: 99;
}

.attr_type {
	font-weight: 800;
	font-style: bold;
}
.attr_value {
	font-weight: 200 !important;
	font-style: normal;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
	Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	color: #ffffff;
}
.base-btn {
	border: solid 1px var(--light-gold-fade);
	color: var(--platinum);
	border-radius: 15px;
	padding: 5px 45px;
	font-size: 0.6em;
	font-family: var(--secondary-font);
	font-weight: 200;
	letter-spacing: 0.2em;
}

.base-btn-claim {
	border: solid 1px var(--light-gold-fade);
	color: var(--platinum);
	border-radius: 15px;
	padding: 8px 10px 5px 10px;
	font-size: 0.6em;
	font-family: var(--secondary-font);
	font-weight: 200;
	letter-spacing: 0.2em;
}
.base-btn-invert {
	border: solid 2px var(--light-gold);
	background-color: var(--light-gold);
	color: var(--jetblack);
	border-radius: 15px;
	padding: 5px 45px;
	font-size: 0.6em;
	font-family: var(--secondary-font);
	font-weight: 200;
	letter-spacing: 0.2em;
}
.base-btn-connect {
	border: solid 1px var(--light-gold-fade);
	color: var(--platinum);
	border-radius: 15px;
	padding: 8px 10px 5px 10px;
	font-size: 0.6em;
	font-family: var(--secondary-font);
	font-weight: 200;
	letter-spacing: 0.4em;
}
.base-btn-action {
	border: solid 1px var(--light-gold);
	background-color: var(--light-gold);
	color: var(--jetblack);
	border-radius: 25px;
	padding: 5px 45px;
	font-size: 0.9em;
	font-family: var(--secondary-font);
	letter-spacing: 0.2em;
	line-height: 2em;
}
.base-btn-asc {
	border: solid 1px var(--light-gold);
	background-color: var(--light-gold);
	color: var(--jetblack);
	border-radius: 25px;
	padding: 5px 50px;
	margin: 10px;
	font-size: 0.7em;
	font-family: var(--secondary-font);
	z-index: 3;
}
.base-btn-asc-mobile {
	border: solid 1px var(--light-gold);
	background-color: var(--light-gold);
	color: var(--jetblack);
	border-radius: 25px;
	padding: 5px 50px;
	margin: 10px;
	font-size: 0.7em;
	font-family: var(--secondary-font);
	z-index: 3;
	width: 200px;
}
.base-btn-asc-alt {
	border: solid 1px var(--light-gold);
	background-color: none;
	color: var(--light-gold);
	border-radius: 25px;
	padding: 5px 50px;
	margin: 10px;
	font-size: 0.7em;
	font-family: var(--secondary-font);
}
.base-btn-asc-alt-mobile {
	border: solid 1px var(--light-gold);
	background-color: none;
	color: var(--light-gold);
	border-radius: 25px;
	padding: 5px 50px;
	margin: 10px;
	font-size: 0.7em;
	font-family: var(--secondary-font);
	width: 200px;
}
.base-btn-danger {
	background-color: #8e3434;
	border-radius: 25px;
	padding: 7px 20px;
	margin: 10px;
	font-size: 0.7em;
	font-family: var(--secondary-font);
}
.base-btn-success {
	background-color: #5f7b5b;
	color: var(--platinum);
	border-radius: 25px;
	padding: 7px 20px;
	margin: 10px;
	font-size: 0.7em;
	font-family: var(--secondary-font);
}
.btn-connected {
	/* border: solid 1px #ffffff; */
	font-size: 0.8em;
	font-family: var(--secondary-font);
	color: var(--success);
	border-radius: 15px;
	padding: 8px 10px 5px 8px;
	margin-top: 5px;
	margin-right: 6px;
	letter-spacing: 0.3em;

	margin-bottom: 3px;
	margin-left: 20px;
}
.btn-incorrect-network {
	border: solid 1px #ffffff00;
	font-size: 0.6em;
	font-family: var(--secondary-bold);
	color: var(--caution);
	border-radius: 20px;
	padding: 5px 20px;
	margin-top: 5px;
	letter-spacing: 0.3em;
}

.battleground {
	height: 100%;
	/* max-height: -webkit-fill-available; */
	width: 100%;
	position: fixed;
	top: 0;
	object-fit: cover;
	z-index: 0;
	top: 0;
	left: 0;
}
.bg_success {
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	object-fit: cover;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
}

.bb {
	/* border: 1px solid blue; */
	text-align: center;
}
.base-blank-btn {
	border: solid 1px #ffffff00;
	font-size: 0.6em;
	font-family: var(--main-font);
	color: var(--platinum);
	border-radius: 20px;
	padding: 5px 20px;
	margin: 10px;
}
.base-blank-btn-selected {
	border: solid 1px #ffffff00;
	font-size: 0.6em;
	font-family: var(--main-font);
	color: var(--light-gold);
	border-radius: 20px;
	padding: 5px 20px;
	margin: 10px;
}
.base-blank-btn-mobile {
	border: solid 1px #ffffff00;
	font-size: 1em;
	font-family: var(--main-font);
	color: var(--platinum);
	border-radius: 20px;
	padding: 5px 20px;
	margin: 10px;
}
.base-blank-btn-mobile-selected {
	border: solid 1px #ffffff00;
	font-size: 1em;
	font-family: var(--main-font);
	color: var(--light-gold);
	border-radius: 20px;
	padding: 5px 20px;
	margin: 10px;
}

.bghaiku {
	opacity: 30%;
}

.bg_success {
	height: 100%;
	width: 100%;
	top: 0;
	object-fit: cover;
	position: fixed;
	z-index: 0;
	top: 0;
	left: 0;
}
.bgwrapper {
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	object-fit: cover;
}

.bgvideo {
	z-index: 0 !important;
}

.bighead_logo {
	justify-content: center;
	align-items: center;
	width: auto;
	margin: 0 auto;
	margin-top: 10%;
}

#caption {
	margin: auto;
	display: block;
	width: 80%;
	max-width: 700px;
	text-align: center;
	color: #ccc;
	padding: 10px 0;
	height: 150px;
}

.claim_alert {
	position: absolute;
	z-index: 3;
	top: 80%;
	left: 20%;
	right: 20%;
}

/* .close_modal_btn {
	position: relative;
	border: solid 1px var(--jetblack);
	background-color: transparent;
	font-family: var(--main-font);
	color: var(--light-gold);
	border-radius: 25px;
	padding: 10px 10px;
	font-size: 0.7em;
	z-index: 1;
} */

.cloud_transition {
	height: 300px;
	z-index: 4;
}
.cloud_transition_2 {
	height: 300px;
	z-index: 4;
}

.collection_info {
	text-align: center;
	margin-bottom: 10px;
	margin-top: 10px;
	font-family: var(--secondary-font);
}

.contact_icon {
	width: auto;
	height: 30px;
	display: inline-block;
	cursor: pointer;
}
.contact_icon_mobile {
	width: auto;
	height: 35px;
	display: inline-block;
	cursor: pointer;
}

.contact_row {
	position: relative;
	width: auto;
	margin-left: 20%;
}

.contact_us {
	margin-right: 10%;
	margin-top: 15%;
}
.container {
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
}

.content_container {
	position: relative;
}

.copy_mobile {
	font-weight: 300;
	font-size: 0.5em;
}

#currentRonin {
	border-radius: 5px;
	cursor: pointer;
	transition: 0.3s;
}

#currentRonin:hover {
	opacity: 0.7;
}

.danger_tagline {
	font-family: var(--main-bold);
	text-align: left;
	color: var(--danger);
}

.debugger {
	border: 2px solid purple;
	border-radius: 5px;
	width: 300px;
	background-color: white;
	text-align: center;
	z-index: 999;
	opacity: 75%;
	position: absolute;
	top: 0;
	right: 0;
}
.debugger_collapsed {
	border-radius: 5px;
	width: 300px;
	height: 50px;
	background-color: white;
	text-align: center;
	z-index: 999;
	opacity: 75%;
	position: absolute;
	top: 1;
	right: 0;
}

footer {
	display: flex;
	bottom: 0;
	right: 0;
	left: 0;
	height: 300px;
	width: 100%;
	background-color: #ffffff10;
	padding: 20px 10%;
	/* z-index: 999; */
}

.footer_content {
	display: grid;
	/* grid-template-columns: 1fr 1fr 1fr 1fr; */
	grid-template-columns: 1fr 1fr 1fr;
	height: 200px;
	width: 100vw;
	/* border: 1px solid blue; */
}

.footer_form {
	display: grid;
	grid-template-rows: 1fr 1fr 1fr;
	gap: 3px;
	height: 150px;
}

.footer_highlight {
	color: var(--platinum);
	font-style: bold;
	font-family: var(--secondary-bold);
}

.footer_msg {
	font-size: 0.8em;
}

.footer_logo {
	width: 230px;
}
.footer_logo_mobile {
	width: 300px;
}

.footer_links {
	margin-left: 30%;
	margin-top: 15%;
}

.footer_submit {
	width: 200px;
	height: 30px;
}
.footer_submit_mobile {
	width: 150px;
	height: 30px;
	font-size: 0.7em;
}

.footer_text_link {
	font-family: var(--secondary-font);
	font-size: 0.9em;
	cursor: pointer;
}

.fog_container {
	height: 100%;
	width: 100%;
	position: relative;
}

.fog_low {
	opacity: 100%;
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
}
.fog_mid {
	opacity: 70%;
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
}
.fog_high {
	opacity: 100%;
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
}

.footer_fog {
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
}

.general_layout {
	background-color: var(--jetblack);
	color: var(--white);
}
.general {
	color: var(--white);
}
.gold_font {
	color: var(--light-gold);
}

.haze_low {
	opacity: 100%;
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: -0.1;
	top: 0;
	left: 0;
	transform: rotate(180deg);
}
.haze_mid {
	opacity: 70%;
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: -0.1;
	top: 0;
	left: 0;
	transform: rotate(180deg);
}
.haze_high {
	opacity: 100%;
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: -0.1;
	top: 0;
	left: 0;
}

header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 10%;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	z-index: 99;
	transition: all 0.4s ease;
	height: 60px;
}

.heaven {
	width: calc(100vw * 1.15);
	height: calc(100vw * 0.6);
	opacity: 94%;
	z-index: 7; /* <-- Haha Get It? 7th Heaven.*/
}

.hero {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
}

.hide {
	display: none;
}

.home_icon {
	height: 50px;
	width: 50px;
	position: absolute;
	left: 50px;
	cursor: pointer;
}

.input_underline {
	background-color: #ffffff00;
	border-bottom: 1px solid #f2f2f240;
	width: 300px;
	outline: none;
	font-family: var(--secondary-main);
	padding: none;
	height: 35px;
	letter-spacing: 0.2em;
	font-weight: 300;
	font-size: 0.7em;
}
.input_underline_mobile {
	background-color: #ffffff00;
	border-bottom: 1px solid #f2f2f240;
	width: 200px;
	outline: none;
	font-family: var(--secondary-main);
	padding: none;
	height: 35px;
	letter-spacing: 0.2em;
	font-weight: 300;
	font-size: 0.5em;
}

.intersection {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: auto;
	border: 2px solid black;
	opacity: 50%;
	z-index: 3;
	transform: translate(-50%, -50%);
}

.jisei_intro_img {
	width: 100%;
}
.jisei_intro_img_mobile {
	width: 120vw;
	margin: 0 auto;
	padding-bottom: 50px;
	margin-left: -10vw;
}

.kamon_chamber {
	display: grid;
	grid-template-rows: 1fr 1fr;
	height: 150px;
	width: auto;
	cursor: pointer;
}
.kamon_chamber_high {
	margin-bottom: 15px;
}
.kamon_chamber_low {
	margin-top: 25px;
}

.kamon_navbar {
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	height: 170px;
	width: 52.5%;
	justify-content: center;
	align-items: center;
	z-index: 5;
}

.kamon_id {
	font-family: var(--main-font);
	margin-top: 15px;
}
.kamon_icon {
	height: 70px;
	width: 70px;
	margin-bottom: 5px;
	margin: 0 auto;
	z-index: 5;
}

.kamon-menu-item {
  cursor: pointer;
}

.kitsune_form {
	display: grid;
	grid-template-rows: 1fr 1fr 1fr;
	font-family: var(--secondary-font);
}
.kitsune_form_mobile {
	display: grid;
	grid-template-rows: 1fr 1fr 1fr;
	font-family: var(--secondary-font);
	justify-content: center;
	align-items: center;
	padding: 0;
}

.kitsune_submit {
	width: 200px;
	color: var(--light-gold-fade);
	height: 30px;
	padding-top: -30px;
}
.kitsune_submit_mobile {
	width: 170px;
	color: var(--light-gold);
	border: solid 1px var(--light-gold);
	height: 35px;
	font-size: 0.7em;
	border-radius: 30px;
}

.new_mint_block {
	/* border: 1px solid red; */
	max-width: 100%;
	max-height: 100%;
	height: 100%;
	margin-top: 50px;
}

.mint_block {
	position: relative;
	top: 0;
	max-width: 100%;
	max-height: 100%;
	width: calc(100vw * 0.357);
	height: calc(100vw * 0.417);
	z-index: -0.1;
}

.mint_body {
	position: relative;
	justify-content: center;
	align-items: center;
	width: calc(100vw * 0.278);
	height: calc(100vh * 0.6);
	text-align: center;
	margin: 0 auto;
	margin-top: -90%;
	/* border: 1px solid pink; */
}

.main-font {
  font-family: var(--main-font);
}

.secondary-font {
  font-family: var(--secondary-font);
}

.gold-color {
  color: var(--light-gold)
}

.gold-background {
  background-color: var(--light-gold)
}

.gold-border {
	border: solid 1px var(--light-gold);
}

.mint-btn {
	position: relative;
	top: calc(100vw * 0.075);
	border: none;
	background-color: var(--light-gold);
	font-family: var(--secondary-font);
	font-weight: 400;
	letter-spacing: 0.2em;
	color: var(--jetblack);
	border-radius: 25px;
	padding: 1em 5.5em 0.7em 5.5em;
	/* width: calc(100vw * .18); */
	font-size: 0.7em;
	z-index: 1;
}
.mint-btn-success {
	position: relative;
	top: calc(100vw * 0.075);
	border: solid 1px var(--jetblack);
	background-color: var(--light-gold);
	font-family: (var(--secondary-font));
	color: var(--jetblack);
	border-radius: 25px;
	padding: 10px 60px;
	width: calc(100vw * 0.18);
	font-size: 0.5em;
	z-index: 1;
}

.mint_container {
	width: 100vw;
	height: 100vh;
	/* border: 1px solid white; */
	display: flex;
	flex: 1;
	justify-content: space-evenly;
	align-items: center;
}

.mint_error_text {
	font-weight: 800;
	font-size: 0.8em;
	color: var(--danger);
}
.mint_success_text {
	font-weight: 800;
	font-size: 0.8em;
	color: var(--success);
}
.mint_success_title {
	/* border: 1px solid blue; */
	font-size: 2.5em;
	font-family: var(--main-bold);
}

.mint_limit_warning {
	position: absolute;
	left: 20%;
	margin: 0 auto;
	color: var(--platinum);
	font-family: var(--secondary-font);
	/* width: calc(130vw * .2084) */
}
.mint_status {
	position: absolute;
	top: 30%;
	left: calc(20vw * 0.224);
	right: calc(20vw * 0.224);
	z-index: 2;
}
.etherscan_status_text {
	font-size: 0.8em;
	font-family: var(--secondary-font);
	color: var(--platinum);
}
.mint_status_text {
	font-size: 0.8em;
	font-family: var(--secondary-bold);
	color: var(--light-gold);
}
.mint_title {
	font-family: var(--main-bold);
	font-size: 2em;
	z-index: 0.5;
}
.mint_tagline {
	font-family: var(--main-bold);
	font-size: 0.8em;
	margin-top: -30px;
	z-index: 0.5;
}
.mint_ticket {
	position: relative;
	width: calc(100vw * 0.2434);
	margin: 0 auto;
}
.mint_ticket_container {
	position: relative;
	max-width: 100%;
}
.mint_quantity {
	font-family: var(--main-bold);
	background-color: #1d1d1d70;
	border: 1px solid #1d1d1d80;
	height: 120px;
	padding-top: 10px;
	margin-bottom: 30px;
	overflow: visible;
	border-radius: 15px;
	font-size: 4em;
	z-index: 0.5;
	letter-spacing: 0.1em;
}
.mint-up-down {
	background-color: var(--jetblack);
	font-family: var(--main-bold);
	/* border-radius: 15px; */
	width: 40px;
	margin: 0 auto;
}
.attr {
	/* border: purple 1px solid; */
	margin: 0 auto;
	display: block;
}
.mobile_attr {
	/* border: 1px solid blue; */
	margin: 0 auto;
	display: block;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.mobile_menu {
	display: none;
}
.mobile-menu-padding {
	margin-bottom: 50px;
	font-size: 1.2em;
}
.modal {
	display: none;
	position: fixed;
	z-index: 1;
	padding-top: 100px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.9);
}
.modal_open {
	display: block;
	position: fixed;
	z-index: 1;
	padding-top: 5vh;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	/* overflow: auto; */
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.9);
}

.modal-content,
#caption {
	animation-name: zoom;
	animation-duration: 0.6s;
}

@keyframes zoom {
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
	.modal-content {
		width: 100%;
	}
	.modal_open {
		padding-top: 25vh;
	}
}

/* Modal Content (Image) */
.modal-content {
	margin: auto;
	display: block;
	width: 80%;
	max-width: 700px;
}

.opening_logo_container {
	height: 500px !important;
	justify-content: center;
	align-items: center;
	margin-top: 100px;
	z-index: 2;
	/* border: 2px solid red; */
}
.opening_logo_container_mobile {
	height: 500px !important;
	justify-content: center;
	align-items: center;
	margin-top: 100px;
	z-index: 2;
	/* border: 2px solid green; */
}
.opening_logo {
	height: var(--opening-logo-height);
	z-index: 2 !important;
	margin: 0 auto;
	position: relative;
}
.opening_logo_mobile {
	height: var(--opening-logo-height);
	z-index: 2 !important;
	margin: 0 auto;
	position: relative;
}
.opening_type {
	font-family: var(--main-bold);
	z-index: 3;
	position: relative;
	bottom: var(--opening-typeface-margin);
	font-size: 2.7em;
	font-weight: 800;
	overflow: visible;
}
.opening_type_mobile {
	font-family: var(--main-bold);
	z-index: 3;
	position: relative;
	bottom: var(--opening-typeface-margin);
	font-size: 2.7em;
	font-weight: 800;
	overflow: visible;
}

p {
	font-size: 1em;
}
.placeholder {
	background: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.profile_columns {
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.profile_container {
	height: 100vh;
	width: 100%;
	display: grid;
	grid-template-rows: 1fr 4fr;
	text-align: center;
	justify-content: center;
	align-items: center;
}
.profile_content {
	width: 100%;
	display: grid;
}

.profile_controls_mobile {
	display: block;
	margin: 0 auto;
	width: 80vw;
	margin-bottom: 20px;
}
.profile_nav_row {
	width: 100%;
}
.profile_rows {
	display: grid;
	grid-template-rows: 1fr 1fr;
	margin: 0 auto;
}
.profile_title {
	font-family: var(--main-bold);
	text-align: center;
	font-size: 3em;
	z-index: 0.5;
}
.profile_scrollview {
	height: 70vh;
	width: 80vw;
	/* border: 1px solid #ffffff10; */
	bottom: 0;
	overflow-y: auto;
	z-index: 99;
}
.return_right {
	float: right;
}
.roadmap_content {
	font-family: var(--secondary-font);
	color: var(--platinum);
}
.roadmap_content_alt {
	font-family: var(--secondary-font);
	color: var(--light-gold);
}
.roadmap_section_alt_title {
	font-family: var(--main-bold);
	color: var(--platinum);
	font-size: 2em;
}
.roadmap_section_title {
	font-family: var(--main-bold);
	color: var(--light-gold);
  font-size: 1.7em;
}
.roadmap_title_mobile {
	font-family: var(--main-bold);
	color: var(--platinum);
	font-size: 2.2em;
	text-align: center;
}
.roadmap_title_mobile_sm {
	font-family: var(--main-bold);
	color: var(--platinum);
	font-size: 0.8em;
	text-align: center;
}
.roadmap_section_title_right {
	font-family: var(--main-bold);
	color: var(--light-gold);
	font-size: 2em;
	text-align: right;
}
.ronin-disabled {
	filter: grayscale(100%);
	cursor: not-allowed;
}

.ronin_id {
	font-family: var(--main-bold);
	text-align: left;
	color: var(--light-gold);
}
.ronin_img_responsive {
	outline: var(--light-gold) solid 2px !important;
	height: 300px;
	width: 300px;
	margin: 0 auto;
	z-index: 5;
	background: black;
}
.ronin_img_hidden {
	display: none;
	outline: var(--light-gold) solid 2px !important;
	height: 300px;
	width: 300px;
	margin: 0 auto;
	z-index: 5;
	background: black;
}
.ronin-img {
	background: black;
	outline: var(--light-gold) solid 1px !important;
}
.ronin_indicator {
	margin-top: 20px;
}
.ronin-img-disabled {
	filter: brightness(10%);
}
.ronin-img-selected {
	border-color: var(--success);
	filter: brightness(1.5);
}
.ronin_title {
	padding: 0;
	font-family: var(--main-bold);
	text-align: left;
	font-size: 3em;
	z-index: 0.5;
}
.ronin_title_mobile {
	padding: 0;
	font-family: var(--main-bold);
	text-align: center;
	font-size: 3em;
	z-index: 0.5;
}
.roninSelector {
	/*margin-top: 100px;*/
	display: grid;
	grid-template-columns: repeat(4, auto);
	gap: 20px;
	justify-content: center;
}
.section_1_container {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	position: fixed;
}
.section_1_container_mobile {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	position: fixed;
}
.section_1_container_hidden {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	position: fixed;
}
.section_1_intersection {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
}
.section_2_intersection {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
}
.section_2_container {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
}
.section_3_container {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
}
.section_4_container {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
}
.section_5_container {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
}
.section_6_container {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
}
.section_content {
	text-align: left;
	margin-bottom: 10px;
	margin-top: 10px;
	font-family: var(--secondary-font);
	z-index: 0.5;
}
.section_content_sm {
	text-align: justify;
	text-align-last: left;
	margin-bottom: 10px;
	margin-top: 10px;
	font-family: var(--secondary-font);
	z-index: 0.5;
	font-size: 0.7em;
}
.section_content_center_sm {
	text-align: center;
	margin-bottom: 10px;
	margin-top: 10px;
	font-family: var(--secondary-font);
	z-index: 0.5;
	font-size: 0.7em;
}
.section_content_center {
	text-align: center;
	margin-bottom: 10px;
	margin-top: 10px;
	font-family: var(--secondary-font);
	z-index: 0.5;
}

.section_highlight {
	font-family: var(--secondary-font);
	color: var(--light-gold);
	font-weight: 600;
	z-index: 0.5;
}

.section_tagline {
	font-family: var(--main-bold);
	text-align: left;
	font-size: 1em;
	margin-top: -10px;
	z-index: 0.5;
}
.section_tagline_sm {
	font-family: var(--main-bold);
	text-align: left;
	font-size: 0.8em;
	margin-top: -10px;
	z-index: 0.5;
}

.section_tagline_center {
	font-family: var(--main-bold);
	text-align: center;
	font-size: 0.8em;
	z-index: 0.5;
}
.section_title {
	font-family: var(--main-bold);
	text-align: left;
	font-size: 3em;
	z-index: 0.5;
}
.section_title_center {
	font-family: var(--main-bold);
	text-align: center;
	font-size: 2.5em;
	z-index: 0.5;
}

.status_light_off {
	height: 15px;
	width: 15px;
	background-color: var(--platinum);
	margin-right: 10px;
	border-radius: 25px;
	background-color: #ffffff00;
}

.status_light_on {
	height: 15px;
	width: 15px;
	margin-right: 10px;
	background-color: var(--success);
	border-radius: 25px;
	background-color: #ffffff00;
}

.sub_title {
	font-family: var(--main-bold);
	text-align: left;
	font-size: 3em;
}

.success_tagline {
	font-family: var(--main-bold);
	text-align: left;
	color: var(--success);
}

.tokenomics_metrics {
	color: var(--light-gold);
	font-family: var(--main-font);
	font-size: 2em;
	font-weight: bold;
}

.tokenomics_label {
	font-weight: 600;
}

.torii_bg {
	position: absolute;
	height: var(--content-bg-torii-height);
	opacity: 30%;
	top: 0;
	right: 0;
	z-index: -1;
}

.torii_hd {
	z-index: -0.5;
	max-width: 100%;
	max-height: 100%;
	bottom: 0;
}
.torii_hd_mobile {
	position: absolute;
	opacity: 50%;
	z-index: -0.5;
	max-width: 100%;
	max-height: 100%;
	bottom: 0;
	border: 1px solid blue;
}

.traits {
	width: 520px;
}

.trait_pill {
	border: 1px solid rgb(10, 145, 255);
	background-color: white;
	border-radius: 10px;
	padding: 5px 25px;
	margin: 10px;
	width: 120px;
}
.trait_type {
	color: rgb(10, 145, 255);
	font-size: 8px;
	font-weight: 600;
}
.trait_name {
	color: rgb(10, 145, 255);
	font-size: 8px;
}

video {
	object-fit: cover;
	width: 100vw;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0.5;
}

.wallet_controls {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* border: 1px solid var(--light-gold); */
	width: 200px;
}
/* BASE STYLES END*/

/* NAVBAR BREAKPOINT 1 (SCALES BUTTONS/FONTS) */
@media screen and (max-width: 1260px) {
	.base-blank-btn {
		font-size: 0.4em;
		padding: 5px 10px;
	}
}
/* NFT DETAILS PAGE Secondary Breakpoint */
@media screen and (max-width: 1200px) {
}

/* NAVBAR BREAKPOINT 2 (CONCEALS LINKS, MOBILE MENU ONLY)*/
@media screen and (max-width: 1060px) {
	.nav_links {
		display: none;
	}

	header {
		justify-content: space-between;
	}

	.mobile_menu {
		display: block;
	}
	.mint-btn-success {
		width: 200px;
		font-size: 0.3em;
		top: 120px;
		padding: 10px 30px;
	}
}

@media screen and (min-width: 950px) {
	.mint_block_success {
		position: relative;
		top: 0;
		max-width: 100%;
		max-height: 100%;
		width: calc(100vw * 0.357);
		height: calc(100vw * 0.417);
		z-index: -0.1;
	}
}

/* BREAKPOINT 3 */

@media screen and (max-width: 950px) {
	.ascend_img {
		width: calc(100vw * 0.2084);
		height: calc(100vw * 0.2084);
	}

	.ascend_img_container {
		position: absolute;
		top: calc(100vw * 0.07);
		margin-left: calc(100vw * 0.0592);
	}

	.ascension_block {
		position: relative;
		max-width: 100%;
		max-height: 100%;
		width: calc(100vw * 0.357);
		height: calc(100vw * 0.417);
		z-index: -0.1;
	}

	.ascend_ticket {
		position: relative;
		width: calc(100vw * 0.2434);
		margin: 0 auto;
	}

	.base-btn-danger {
		background-color: #8e3434;
		border-radius: 25px;
		padding: 7px 20px;
		margin: 10px;
		font-size: 0.7em;
		font-family: var(--secondary-font);
	}
	.base-btn-success {
		background-color: #5f7b5b;
		color: var(--platinum);
		border-radius: 25px;
		padding: 7px 20px;
		margin: 10px;
		font-size: 0.7em;
		font-family: var(--secondary-font);
	}

	.img_container_responsive {
		width: 120vw;
		margin: 0 auto;
		margin-left: -65px;
	}
	.ronin_container_responsive {
		width: 120vw;
		margin: 0 auto;
		margin-left: -35px;
	}

	.section_content {
		font-size: 0.9em;
	}

	.section_title {
		font-size: 2.1em;
	}

	.kamon_chamber {
		margin-left: 25px;
		margin-right: 25px;
	}

	.kamon_icon {
		height: 50px;
		width: 50px;
		margin: 0 auto;
		z-index: 5;
	}

	.kamon_id {
		margin-bottom: -5px;
		font-size: 0.6em;
	}

	.mint_block {
		position: relative;
		max-width: 100%;
		max-height: 100%;
		width: calc(100vw * 0.667);
		height: calc(100vh * 0.727);
		z-index: -0.1;
		left: calc(100vw * 0.07);
		top: calc(100vw * 0.03);
		/* border: 1px solid blue; */
	}

	.mint_body {
		position: relative;
		justify-content: center;
		align-items: center;
		width: calc(100vw * 0.7);
		margin: 0 auto;
		margin-top: -60%;
		text-align: center;
		/* border: 1px solid orange; */
	}

	.mint-btn {
		width: 200px;
		font-size: 0.7em;
		top: 110px;
		padding: 1em 5.5em 0.7em 5.5em;
	}

	.mint_error_text {
		font-weight: 800;
		font-size: 0.7em;
		color: var(--danger);
	}

	.mint_success_text {
		font-weight: 800;
		font-size: 0.7em;
		color: var(--success);
	}

	.mint_limit_warning {
		position: absolute;
		margin: 0 auto;
		margin-top: 20px;
		color: var(--platinum);
		/* left: 21%; */
		/* width: calc(130vw * .2084) */
	}

	.mint_status {
		position: absolute;
		top: 40%;
		right: calc(20vw * 0.124);
		z-index: 2;
	}
	.etherscan_status_text {
		font-weight: 400;
		font-size: 0.5em;
	}
	.mint_status_text {
		font-weight: 400;
		font-size: 0.5em;
	}

	.mint_ticket {
		position: relative;
		width: calc(210vw * 0.2434);
		margin: 0 auto;
		top: 10%;
	}

	.mint_title {
		font-family: var(--main-bold);
		font-size: 1.9em;
		z-index: 0.5;
	}

	button.open {
		margin-left: 10px !important;
		margin-top: 60px !important;
	}

	#hamburger_menu {
		margin-left: 25px !important;
		margin-top: 10px !important;
	}

	.roninSelector {
		/*margin-top: 100px;*/
		display: grid;
		grid-template-columns: repeat(2, auto) !important;
		gap: 20px;
		justify-content: center;
	}
}

/* Mobile Styles Begin*/

@media screen and (max-width: 820px) {
	.mint_body {
		margin-top: -80%;
	}
}
@media screen and (max-width: 700px) {
	.mint_body {
		margin-top: -80%;
	}
}
@media screen and (max-width: 600px) {
	body {
		background-color: var(--jetblack);
	}

	.alert-container {
		background-color: #f2f2f210;
		border-radius: 15px;
		width: 290px;
		height: 150px;
	}

	.asc_intro_text {
		margin-top: 110px;
	}
	.ascend_img {
		width: 265px;
		height: 265px;
	}

	.ascend_img_container {
		position: absolute;
		top: 100px;
		margin-left: 10vw;
		margin-right: 10vw;
	}

	.ascension_block {
		position: relative;
		max-width: 100%;
		max-height: 100%;
		width: 97vw;
		height: 500px;
		z-index: -0.1;
	}

	.asc_controls {
		margin-top: 40px;
	}

	.asc_prompt {
		margin-left: 15px;
		margin-right: 15px;
	}

	.ascend_ticket {
		position: relative;
		width: 90vw;
		margin: 0 auto;
	}

	.base-btn-danger {
		background-color: #8e3434;
		border-radius: 25px;
		padding: 7px 15px 7px 15px !important;
		margin: 10px;
		font-size: 0.8em;
		font-family: var(--secondary-font);
	}
	.base-btn-success {
		background-color: #5f7b5b;
		color: var(--platinum);
		border-radius: 25px;
		padding: 7px 15px 7px 15px !important;
		margin: 10px;
		font-size: 0.8em;
		font-family: var(--secondary-font);
	}

	.hero {
		height: 110vh;
		justify-content: center;
		align-items: flex-start;
	}
	video {
		display: none;
	}

	footer {
		display: flex;
		bottom: 0;
		right: 0;
		left: 0;
		height: 200px;
		width: 100%;
		background-color: var(--jetblack);
		padding: 20px 20%;
	}

	.footer-fill {
		background-color: #00000020;
		color: white;
	}

	footer::after {
		z-index: 999;
	}

	.mint_block {
		position: relative;
		max-width: 100%;
		max-height: 100%;
		width: calc(100vw * 0.667);
		height: calc(100vw * 0.727);
		z-index: -0.1;
		left: calc(40vw * 0.2);
		top: calc(100vw * 0.03);
	}

	.mint-btn {
		width: 200px;
		font-size: 1.2em;
		top: 85px;
		padding: 0.5em -10px 0.1em -10px !important;
	}
	.mint-btn-success {
		width: 200px;
		font-size: 0.5em;
		top: 85px;
		padding: 10px 40px;
	}

	.mint_body {
		position: relative;
		justify-content: center;
		align-items: center;
		width: calc(100vw * 0.7);
		margin: 0 auto;
		text-align: center;
		/* border: 1px solid yellow; */
		margin-top: -60%;
	}

	.mint_status {
		position: absolute;
		top: 35%;
		right: calc(25vw * 0.124);
		z-index: 2;
	}

	.mint_quantity {
		border-radius: 15px;
		font-size: 2.5em;
	}
	.mint-up-down {
		/* border-radius: 15px; */
		padding-bottom: -10px;
		padding-top: -10px;
		margin: 0 auto;
		font-family: var(--secondary-font);
		font-weight: 200;
		font-size: 2em;
	}

	.mint_ticket_success {
		position: relative;
		width: calc(250vw * 0.2434);
		margin: 0 auto;
		top: 10%;
	}

	.mint_limit_warning {
		position: absolute;
		left: calc(100vw * 0.18);
		margin: 0 auto;
		margin-top: 20px;
		color: var(--platinum);
		font-size: 0.6em;
		/* width: calc(130vw * .2084) */
	}

	.kamon_chamber {
		/* border: 1px solid grey; */
		margin-left: 10px;
		margin-right: 10px;
	}

	.kamon_icon {
		height: 50px;
		width: 50px;
		margin: 0 auto;
		z-index: 5;
		/* border: 2px solid blue; */
	}

	.kamon_id {
		margin-top: -15px;
		font-size: 0.6em;
		/* border: 1px solid yellow; */
		height: 10px;
	}

	.kamon_navbar_responsive {
		position: relative;
		display: grid;
		grid-template-rows: 1fr 1fr 1fr;
		height: 170px;
		width: 52.5%;
		justify-content: center;
		align-items: center;
		z-index: 5;
	}

	.kamon_sub_row {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.roninSelectorMobile {
		margin-top: 100px;
		display: grid;
		grid-template-columns: repeat(auto) !important;
		gap: 20px;
		justify-content: center;
	}

	.section_1_container {
		height: 120vh;
		width: 100%;
		display: flex;
		text-align: center;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.section_1_container_mobile {
		height: 820px;
		width: 100%;
		display: flex;
		text-align: center;
		justify-content: center;
		align-items: center;
		position: relative;
	}

	.mobile-ronin-text {
		width: 295px;
		/* margin-left: 20%; */
		margin-right: 20%;
	}

	.section_1_intersection,
	.section_2_intersection {
		display: none;
	}

	footer {
		display: flex;
		bottom: 0;
		right: 0;
		left: 0;
		height: 150px;
		width: 100%;
		background-color: #ffffff10;
		padding: 20px 10%;
		margin-top: 90px;
	}

	.footer_line {
		background-color: var(--light-gold);
		width: 250px;
		margin: 0 auto;
		height: 2px;
	}

	.footer_content {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.profile_title {
		font-size: 1.5em;
	}
}

@media screen and (max-width: 500px) {
	.mint-btn {
		width: 180px;
		font-size: 0.4em;
		/* padding: 10px 40px; */
	}
	.mint-btn-success {
		width: 200px;
		font-size: 12px;
		top: 85px;
		padding: 10px 40px 10px 40px;
	}
	.mint_body {
		position: relative;
		justify-content: center;
		align-items: center;
		width: calc(100vw * 0.7);
		margin: 0 auto;
		text-align: center;
		margin-top: -70%;
		/* border: 1px solid orange; */
	}

	.mint_status {
		position: absolute;
		top: 35%;
		right: calc(25vw * 0.124);
		z-index: 2;
	}

	.mint_quantity {
		border-radius: 15px;
		font-size: 2.5em;
	}
	.mint-up-down {
		/* border-radius: 15px; */
		width: 40px;
		margin: 0 auto;
	}

	.tokenomics_metrics {
		font-size: 26px;
		font-weight: 800;
	}
	.tokenomics_label {
		font-size: 0.7em;
	}
	/* <--  Fixing Jumpy Mobile Screens --> */

	.section_1_container {
		height: 820px;
	}
	.section_1_container_hidden {
		height: 820px;
	}
	.section_1_intersection {
		height: 820px;
	}
	.section_2_container {
		height: 820px;
	}
	.section_3_container {
		height: 820px;
	}
	.section_4_container {
		height: 820px;
	}
	.section_5_container {
		/* height: 820px; */
		height: 620px;
	}
	.section_6_container {
		height: 820px;
	}

	/*.battleground {
		height: 900px;
		width: auto;
	}*/

	/* <--  Wallet Controls Settings for Mobile --> */

	.wallet_controls {
		width: 170px;
		/* border: 1px solid yellow; */
	}
	.base-btn-connect {
		font-size: 0.5em;
		white-space: nowrap;
		padding: 8px 10px 5px 8px;
	}
	.btn-connected {
		font-size: 0.7em;
		margin-bottom: 0px;
		margin-right: 0px;
		/* border: 1px solid white; */
		padding: 8px 10px 5px 8px;
		margin-right: 25px;
	}
	.btn-incorrect-network {
		font-size: 0.4em;
	}
	.status_light_off {
		margin-top: 2px;
		/* margin-left: 60px; */
		height: 12px;
		width: 12px;
		background-color: #ffffff00;
	}
	.status_light_on {
		margin-top: 2px;
		margin-left: 40px;
		height: 12px;
		width: 12px;
		background-color: #ffffff00;
	}

	/* <--  Wallet Controls Settings for Mobile End --> */
}

@media screen and (max-width: 415px) {
	.mint_body {
		height: 350px !important;
		max-height: 500px !important;
		padding-right: 10px;
	}
	.mint-btn {
		width: 40vw;
		height: 35px;
		margin-bottom: 10px;
	}
	.mint_block_success {
		margin-top: -200px;
	}
	.mint-btn-success {
		width: 150px;
		font-size: 0.1em;
		top: 75px;
		padding: 5px 5px;
		font-family: (var(--secondary-font));
	}

	.etherscan_status_text {
		font-weight: 400;
		font-size: 0.7em;
	}
	.mint_status_text {
		font-weight: 400;
		font-size: 0.7em;
	}
}

@media screen and (max-width: 395px) {
	.mint-btn {
		width: 40vw;
		height: 35px;
		margin-bottom: 15px;
	}

	.mint_body {
		height: 350px !important;
		max-height: 500px !important;
		margin-top: -70%;
		padding-right: 7px;
	}
	.mint-btn-success {
		width: 150px;
		font-size: 0.1em;
		top: 75px;
		padding: 5px 5px;
	}
	.mint_ticket.mint_ticket_container {
		width: 300px;
	}
}
/* Mobile Styles End*/

html,
body {
	position: relative;
	width: 100%;
	height: 100%;
	/* overflow-x: hidden; */
}

.web3modal-modal-lightbox {
	z-index: 100 !important;
}